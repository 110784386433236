import { URL_APEX } from '../src/config';
import axios from 'axios';
import UserLocation from './UserLocation';

export default async function saveEvent(sistema, token) {
    const dateTime = new Date();
    const date = dateTime.toISOString().split('T')[0]; // formato "yyyy-MM-dd"
    const time = dateTime.toLocaleTimeString(); // formato HH:mm

    try {
        const fetchLocation = async () => {
            const data = await UserLocation(); // Use a função para obter os dados
            var headers = {
                "idmetrica": "2",
                "pagina": sistema,
                "categoria": "Acesso",
                "acao": "redirecionamento",
                "rotulo": token,
                "valor": "1",
                "chave": "1",
                "ip":data.ip,
                "city":data.city,
                "region":data.region,
                "country":data.country,
                "host":data.hostname,
                "network":data.network
            };
            axios.post(URL_APEX, {}, { headers });
        };
        await fetchLocation();
    } catch (error) {
        console.log(date, " | ", time, " |", error);
    }   
}