import React, { useEffect } from 'react';
import { URL_LABORATORIO } from '../src/config';
import { URL_BOLETO } from '../src/config';
import { URL_UNIMEDPOA } from '../src/config';
import saveEvent from '../src/SaveEvent';

const RedirectPage = () => {

  const getParameterByName = (name) => {
    let url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  useEffect(() => {
    async function fetchData() {

        const dateTime = new Date();
        const date = dateTime.toISOString().split('T')[0]; // formato "yyyy-MM-dd"
        const time = dateTime.toLocaleTimeString(); // formato HH:mm
        try {
          const linkLab = getParameterByName('l');
          const linkBol = getParameterByName('b');

          let redirectURL= URL_UNIMEDPOA;
          let urlLaboratorio = URL_LABORATORIO;
          let urlBoleto = URL_BOLETO;    

          if (!urlLaboratorio || !urlBoleto || !redirectURL)
            console.log(date, " | ", time, " | ", 'Falha na Inicialização do ambiente');

          if (urlLaboratorio && urlLaboratorio.indexOf('&token=') === -1) 
            urlLaboratorio += '&token=';
      
          if (urlBoleto && !urlBoleto.endsWith('=')) 
            urlBoleto += '=';    

          if (urlLaboratorio && linkLab) {
            redirectURL = `${urlLaboratorio}${linkLab}`;
            await saveEvent('Laboratorio', linkLab);  
          }
          else if ((urlBoleto) && (linkBol)) {
            redirectURL = `${urlBoleto}${linkBol}`;
            await saveEvent('Boleto', linkBol);  
          }
          else 
          {
            await saveEvent('Link com Erro', window.location.href);  
          }      

          if (redirectURL)     
            window.location.href = redirectURL;
        }
        catch (error) {
          console.log(date, " | ", time, " |", error);
        }
    }
   fetchData();
  }, []);

  return (
    <div>
      { 
        /* */
      }
    </div>
  );
};

function App() {
  return (
    <div>
      <header>
      </header>
      <RedirectPage /> {
        /* */
          }
    </div>
  );
}

export default App;
