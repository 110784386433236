const UserLocation = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const { ip } = await response.json();

    const geoResponse = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await geoResponse.json();

    return {
      ip: data.ip,
      city: data.city,
      region: data.region,
      country: data.country_name,
      hostname: data.hostname,
      network: data.org,
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export default UserLocation;
